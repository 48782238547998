import builder from 'xmlbuilder';
import moment from 'moment';

const generatePaymentFile = ({ payments, parameters }: any): void => {
  // Current Date
  const now = new Date();

  // MsgId
  const msgId = `SEPA-${payments.payment_reference}-${moment(now).format(
    'DD.MM.YYYY-HH:mm'
  )}`;

  const creDtTm = moment(now).format('YYYY-MM-DDTHH:mm:ss.SSS');

  // Create some variables to make our lives easier
  const header = payments;
  const { bank_accounts: bankAccount, payment_details: details } = header;

  const root = builder
    .create('Document', { encoding: 'utf-8' })
    .att('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance')
    .att('xmlns', 'urn:iso:std:iso:20022:tech:xsd:pain.001.001.03')
    .ele('CstmrCdtTrfInitn')
    .ele('GrpHdr')
    .ele('MsgId', msgId)
    .up()
    .ele('CreDtTm', creDtTm)
    .up()
    .ele('NbOfTxs', header.detail_count)
    .up()
    .ele('CtrlSum', (+header.total_amount).toFixed(2))
    .up()
    .ele('InitgPty')
    .ele('Id')
    .ele('OrgId')
    .ele('Othr')
    .ele('Id', bankAccount.oin || '')
    .up()
    .up()
    .up()
    .up()
    .up()
    .up()
    .ele('PmtInf')
    .ele('PmtInfId', msgId)
    .up()
    .ele('PmtMtd', 'TRF')
    .up()
    .ele('NbOfTxs', header.detail_count)
    .up()
    .ele('CtrlSum', (+header.total_amount).toFixed(2))
    .up()
    .ele('PmtTpInf')
    .ele('SvcLvl')
    .ele('Cd', 'SEPA')
    .up()
    .up()
    .up()
    .ele('ReqdExctnDt', moment(now).format('YYYY-MM-DD'))
    .up()
    .ele('Dbtr')
    .ele('Nm', parameters.company_name)
    .up()
    .up()
    .ele('DbtrAcct')
    .ele('Id')
    .ele('IBAN', bankAccount.iban || '')
    .up()
    .up()
    .ele('Ccy', 'EUR')
    .up()
    .up()
    .ele('DbtrAgt')
    .ele('FinInstnId')
    .ele('BIC', bankAccount.bic)
    .up()
    .up()
    .up();

  details.map((payment: any) =>
    root
      .ele('CdtTrfTxInf')
      .ele('PmtId')
      .ele('EndToEndId', 'Irish College of English Limited')
      .up()
      .up()
      .ele('Amt')
      .ele('InstdAmt', { Ccy: 'EUR' }, (+payment.amount).toFixed(2))
      .up()
      .up()
      .ele('CdtrAgt')
      .ele('FinInstnId')
      .ele('BIC', payment.supplier.bic)
      .up()
      .up()
      .up()
      .ele('Cdtr')
      .ele('Nm', `${payment.supplier.surname}, ${payment.supplier.first_name}`)
      .up()
      .up()
      .ele('CdtrAcct')
      .ele('Id')
      .ele('IBAN', payment.supplier.iban)
      .up()
      .up()
      .ele('Ccy', 'EUR')
      .up()
      .up()
  );

  downloadFile(
    root.end({ pretty: true }),
    header.batch_number,
    header.payment_reference,
    header.time_processed
  );
};

const downloadFile = (
  text: string,
  sepaFileCount: number,
  paymentRef: string,
  paymentDate: Date = new Date()
) => {
  const element = document.createElement('a');
  const file = new Blob([text], {
    type: 'text/xml'
  });
  element.href = URL.createObjectURL(file);
  element.download = `SEPA-${paymentRef}-${moment(paymentDate).format(
    'DDMMYYYY'
  )}-${sepaFileCount.toString().padStart(6, '0')}`;
  document.body.appendChild(element);
  element.click();
};

export const fileTools = {
  downloadFile,
  generatePaymentFile
};
