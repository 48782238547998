import React from 'react';
import { Template } from 'devextreme-react/core/template';
import { Button } from 'devextreme-react/button';
import {
  Column,
  DataGrid,
  Editing,
  HeaderFilter,
  IDataGridOptions,
  SearchPanel,
  Selection,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid';

interface IDXDataGridProps extends IDataGridOptions {
  actionButtons?: any[];
  editingMode?: 'cell' | 'row';
  columns: IColumnProps[];
  onAddRecord?: (e: any) => void;
  selectionMode?: 'multiple';
  showHeaderFilters?: boolean;
  showSearchPanel?: boolean;
  summaries?: ISummaryProps[];
}

interface IColumnProps {
  allowEditing?: boolean;
  caption: string;
  dataField: any;
  format?: any;
  width?: any;
  component?: any;
}

interface ISummaryProps {
  column: string;
  type: string;
  summaryType: string;
  valueFormat?: string;
}

const DXDataGrid: React.FC<IDXDataGridProps> = ({
  actionButtons,
  columns,
  editingMode,
  keyExpr,
  onAddRecord,
  selectionMode,
  showHeaderFilters = false,
  showSearchPanel = false,
  summaries,
  ...rest
}) => {
  const handleToolbarPreparing = (e: any) => {
    if (onAddRecord)
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'addButtonTemplate'
      });
  };

  const addButtonRender = () => {
    return onAddRecord ? (
      <Button
        icon="add"
        onClick={onAddRecord}
        stylingMode="contained"
        type="default"
      />
    ) : (
      undefined
    );
  };

  if (!keyExpr) console.warn('No Key set for DXDataGrid!');

  return (
    <DataGrid
      {...rest}
      keyExpr={keyExpr}
      onToolbarPreparing={handleToolbarPreparing}
      rowAlternationEnabled
    >
      {columns
        ? columns.map(col => (
            <Column
              allowEditing={col.allowEditing}
              caption={col.caption}
              dataField={col.dataField}
              key={col.dataField}
              width={col.width}
              format={col.format}
            >
              {col.component ? col.component : undefined}
            </Column>
          ))
        : undefined}
      {actionButtons ? (
        <Column
          type="buttons"
          buttons={actionButtons}
          width="10%"
          alignment="right"
        />
      ) : (
        undefined
      )}
      {editingMode ? <Editing allowUpdating mode={editingMode} /> : undefined}
      <HeaderFilter visible={showHeaderFilters} />
      <SearchPanel visible={showSearchPanel} width="300px" />
      {addButtonRender ? (
        <Template name="addButtonTemplate" render={addButtonRender} />
      ) : (
        undefined
      )}
      <Selection mode={selectionMode} />
      {summaries && summaries.length > 0 ? (
        <Summary>
          {summaries.map(summary => {
            return summary.type === 'total' ? (
              <TotalItem
                key={summary.column}
                column={summary.column}
                summaryType={summary.summaryType}
                valueFormat={summary.valueFormat}
              />
            ) : (
              undefined
            );
          })}
        </Summary>
      ) : (
        undefined
      )}
    </DataGrid>
  );
};

export default DXDataGrid;
