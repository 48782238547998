import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SideNavOuterToolbar as SideNavBarLayout } from '../../layouts';
import { navigation } from '../../app-navigation';
import appInfo from '../../app-info';
import firebaseApp from '../../firebaseConfig';

const BankAccountsBrowse = lazy(() =>
  import('../BankAccounts/BankAccountsBrowse/BankAccountsBrowse')
);

const BankAccountForm = lazy(() =>
  import('../BankAccounts/BankAccountForm/BankAccountForm')
);

const BankIdentifierCodesBrowse = lazy(() =>
  import(
    '../BankIdentifierCodes/BankIdentifierCodesBrowse/BankIdentifierCodesBrowse'
  )
);

const BankIdentifierCodeForm = lazy(() =>
  import('../BankIdentifierCodes/BankIdentifierCodeForm/BankIdentifierCodeForm')
);

const PaymentEnquiry = lazy(() =>
  import('../Payments/PaymentEnquiry/PaymentEnquiry')
);

const ReportViewer = lazy(() => import('../ReportViewer/ReportViewer'));

const SepaFileWizard = lazy(() =>
  import('../Payments/SepaFileWizard/SepaFileWizard')
);

const SupplierAccountsBrowse = lazy(() =>
  import(
    '../Suppliers/SupplierAccounts/SupplierAccountsBrowse/SupplierAccountsBrowse'
  )
);

const SupplierForm = lazy(() =>
  import('../Suppliers/SupplierAccounts/SupplierForm/SupplierForm')
);

const SupplierSubTypesBrowse = lazy(() =>
  import(
    '../Suppliers/SupplierSubTypes/SupplierSubTypesBrowse/SupplierSubTypesBrowse'
  )
);

const SupplierSubTypeForm = lazy(() =>
  import(
    '../Suppliers/SupplierSubTypes/SupplierSubTypeForm/SupplierSubTypeForm'
  )
);

const SupplierTypesBrowse = lazy(() =>
  import('../Suppliers/SupplierTypes/SupplierTypesBrowse/SupplierTypesBrowse')
);

const SupplierTypeForm = lazy(() =>
  import('../Suppliers/SupplierTypes/SupplierTypeForm/SupplierTypeForm')
);

const SystemParameters = lazy(() =>
  import('../System/SystemParameters/SystemParameters')
);

const Home = () => {
  const userMenuItems = [
    // {
    //   text: 'Profile',
    //   icon: 'user'
    // },
    {
      text: 'Logout',
      icon: 'runner',
      onClick: () => firebaseApp.auth().signOut()
    }
  ];

  return (
    <SideNavBarLayout
      menuItems={navigation}
      title={appInfo.title}
      userMenuItems={userMenuItems}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route component={BankAccountsBrowse} exact path="/bank/accounts" />
          <Route component={BankAccountForm} exact path="/bank/accounts/edit" />
          <Route component={BankAccountForm} exact path="/bank/accounts/new" />
          <Route
            component={BankIdentifierCodesBrowse}
            exact
            path="/system/bics"
          />
          <Route
            component={BankIdentifierCodeForm}
            exact
            path="/system/bics/edit"
          />
          <Route
            component={BankIdentifierCodeForm}
            exact
            path="/system/bics/new"
          />
          <Route component={PaymentEnquiry} exact path="/payments/enquiry" />
          <Route
            exact
            path="/reports/SupplierListing"
            render={() => <ReportViewer />}
          />
          <Route
            exact
            path="/reports/PaymentDetail"
            render={() => <ReportViewer />}
          />
          <Route
            exact
            path="/reports/PaymentEnquiry"
            render={() => <ReportViewer />}
          />
          <Route component={SepaFileWizard} exact path="/sepafilewizard" />
          <Route
            component={SupplierAccountsBrowse}
            exact
            path="/suppliers/accounts"
          />
          <Route
            component={SupplierForm}
            exact
            path="/suppliers/accounts/edit"
          />
          <Route
            component={SupplierForm}
            exact
            path="/suppliers/accounts/new"
          />
          <Route
            component={SupplierSubTypesBrowse}
            exact
            path="/suppliers/subtypes"
          />
          <Route
            component={SupplierSubTypeForm}
            exact
            path="/suppliers/subtypes/edit"
          />
          <Route
            component={SupplierSubTypeForm}
            exact
            path="/suppliers/subtypes/new"
          />
          <Route
            component={SupplierTypesBrowse}
            exact
            path="/suppliers/types"
          />
          <Route
            component={SupplierTypeForm}
            exact
            path="/suppliers/types/edit"
          />
          <Route
            component={SupplierTypeForm}
            exact
            path="/suppliers/types/new"
          />
          <Route component={SystemParameters} exact path="/system/parameters" />
          {/* <Route render={() => <div>Page Not Found</div>} /> */}
        </Switch>
      </Suspense>
    </SideNavBarLayout>
  );
};

export default Home;
