import React from 'react';
import { TextBox } from 'devextreme-react/text-box';
import { TextArea } from 'devextreme-react/text-area';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const FormInput = ({
  label,
  name,
  onBlur = null,
  readOnly = false,
  required = false,
  textArea = false
}) => {
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const { errors, setFieldTouched, setFieldValue, touched } = form;

        // Valid until touched, then check for errors
        let valid = true;
        if (!!touched[name] && !!errors[name]) valid = false;

        const handleBlur = () => {
          setFieldTouched(name);

          if (onBlur) onBlur(field.value);
        };

        const handleChanged = props => {
          const { value } = props;

          setFieldValue(name, value.toString(), false);
        };

        const asterisk = required ? <span className="required">*</span> : null;

        return (
          <div className="form-container">
            <label>
              {label} {asterisk}
            </label>

            {textArea ? (
              <TextArea
                {...field}
                className="form-input"
                isValid={valid}
                onFocusOut={handleBlur}
                onValueChanged={handleChanged}
                readOnly={readOnly}
                stylingMode="filled"
                validationError={{
                  message: !!errors[name] ? errors[name] : ''
                }}
                valueChangeEvent="keyup"
              />
            ) : (
              <TextBox
                {...field}
                stylingMode="filled"
                className="form-input"
                isValid={valid}
                onFocusOut={handleBlur}
                onValueChanged={handleChanged}
                readOnly={readOnly}
                validationError={{
                  message: !!errors[name] ? errors[name] : ''
                }}
                valueChangeEvent="keyup"
              />
            )}
          </div>
        );
      }}
    />
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  textArea: PropTypes.bool
};

export default FormInput;
