import React from 'react';
import { DateBox, IDateBoxOptions } from 'devextreme-react/date-box';

interface IAppDateInput extends IDateBoxOptions {
  label?: string;
}

const AppDateInput: React.FC<IAppDateInput> = ({
  displayFormat = 'dd/MM/yyyy',
  label,
  width = '100%',
  ...rest
}) => {
  return (
    <div>
      <label className="input-label">{label}</label>
      <DateBox {...rest} displayFormat={displayFormat} width={width} />
    </div>
  );
};

export default AppDateInput;
