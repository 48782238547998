import notify from 'devextreme/ui/notify';

const appNotification = (message, type = 'success') => {
  notify(
    {
      message,
      position: { my: 'center top', at: 'center top' },
      width: '20vw'
    },
    type
  );
};

export default appNotification;
