import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { AUTH_TOKEN, GRAPHQL_URL } from '../utils/constants';

const getHeaders = () => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const headers = {
    authorization: token ? `Bearer ${token}` : ''
    // 'x-hasura-admin-secret': 'sm2516sr_hasura'
  };
  return headers;
};

const initApolloClient = () => {
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
        // 'Access-Control-Allow-Origin': '*'
        // 'x-hasura-admin-secret': 'sm2516sr_hasura'
      }
    };
  });

  const token = localStorage.getItem(AUTH_TOKEN);
  // create an http link
  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    fetch,
    headers: getHeaders(token)
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false
    })
  });

  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    }
  };

  return client;
};

export default initApolloClient;
