import { gql } from 'apollo-boost';

export const INSERT_PAYMENT_STAGING = gql`
  mutation InsertPaymentStaging($objects: [payment_staging_insert_input!]!) {
    insert_payment_staging(objects: $objects) {
      affected_rows
      returning {
        payment_id
      }
    }
  }
`;

export const COMPLETE_PAYMENTS = gql`
  mutation CompletePayments(
    $payments: [payments_header_insert_input!]!
    $email: String!
  ) {
    insert_payments_header(objects: $payments) {
      returning {
        payment_id
      }
    }
    delete_payment_staging(where: { email: { _eq: $email } }) {
      affected_rows
    }
    update_system_parameters(
      _inc: { sepa_file_count: 1 }
      where: { company_name: { _neq: "" } }
    ) {
      returning {
        sepa_file_count
      }
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation DeletePayment($payment_id: bigint!) {
    delete_payments_detail(where: { payment_id: { _eq: $payment_id } }) {
      affected_rows
    }
    delete_payments_header(where: { payment_id: { _eq: $payment_id } }) {
      affected_rows
    }
  }
`;

export const DELETE_PAYMENT_DETAIL = gql`
  mutation DeletePaymentDetail($payment_detail_id: bigint!) {
    delete_payments_detail(
      where: { payment_detail_id: { _eq: $payment_detail_id } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_PAYMENT_STAGING = gql`
  mutation DeletePaymentStaging($payment_id: Int!) {
    delete_payment_staging(where: { payment_id: { _eq: $payment_id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_PAYMENT_DETAIL_SUCCESSFUL = gql`
  mutation UpdatePaymentDetailSuccessful(
    $payment_detail_id: bigint!
    $successful: Boolean!
  ) {
    update_payments_detail(
      _set: { successful: $successful }
      where: { payment_detail_id: { _eq: $payment_detail_id } }
    ) {
      returning {
        payment_id
      }
    }
  }
`;

export const UPDATE_PAYMENT_STAGING = gql`
  mutation UpdatePaymentStaging(
    $payment_id: Int!
    $subtype: String
    $amount: numeric!
  ) {
    __typename
    update_payment_staging(
      where: { payment_id: { _eq: $payment_id } }
      _set: { supplier_sub_type_code: $subtype, amount: $amount }
    ) {
      returning {
        payment_id
      }
    }
  }
`;

export const UPSERT_BANK_ACCOUNT = gql`
  mutation UpsertBankAccount($objects: [bank_accounts_insert_input!]!) {
    insert_bank_accounts(
      objects: $objects
      on_conflict: {
        constraint: bank_accounts_pkey
        update_columns: [bank_account_code, name, bic, iban, oin]
      }
    ) {
      returning {
        bank_account_code
        name
        bic
        iban
        oin
      }
      affected_rows
    }
  }
`;

export const UPSERT_BANK_IDENTIFIER_CODES = gql`
  mutation UpsertBankIdentifierCodes(
    $objects: [bank_identifier_codes_insert_input!]!
  ) {
    insert_bank_identifier_codes(
      objects: $objects
      on_conflict: {
        constraint: bank_identifier_codes_pkey
        update_columns: name
      }
    ) {
      affected_rows
    }
  }
`;

export const UPSERT_SUPPLIER = gql`
  mutation UpsertSupplier($objects: [suppliers_insert_input!]!) {
    insert_suppliers(
      objects: $objects
      on_conflict: {
        constraint: suppliers_pkey
        update_columns: [
          first_name
          surname
          supplier_type_code
          iban
          bic
          note
        ]
      }
    ) {
      returning {
        supplier_id
        first_name
        surname
        supplier_type_code
        iban
        bic
        note
      }
    }
  }
`;

export const UPSERT_SUPPLIER_SUB_TYPE = gql`
  mutation UpsertSupplierSubType(
    $objects: [supplier_sub_types_insert_input!]!
  ) {
    insert_supplier_sub_types(
      objects: $objects
      on_conflict: { constraint: supplier_sub_types_pkey, update_columns: name }
    ) {
      returning {
        supplier_sub_type_code
        name
      }
    }
  }
`;

export const UPDATE_SYSTEM_PARAMETERS = gql`
  mutation UpdateSystemParameters(
    $systemParameterId: Int!
    $companyName: String!
  ) {
    update_system_parameters(
      _set: { company_name: $companyName }
      where: { system_parameter_id: { _eq: $systemParameterId } }
    ) {
      affected_rows
    }
  }
`;
