import React from 'react';

import Lookup, { ILookupOptions } from 'devextreme-react/lookup';

interface IDataLookupProps extends ILookupOptions {
  label?: string;
}

const AppLookup: React.FC<IDataLookupProps> = ({ label, ...rest }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label className="input-label">{label}</label>
      <Lookup {...rest} />
    </div>
  );
};

export default AppLookup;
