export const navigation = [
  {
    expanded: true,
    icon: 'fas fa-piggy-bank',
    path: '/bank/accounts',
    text: 'Bank Accounts'
  },
  {
    expanded: true,
    text: 'Payments',
    icon: 'fas fa-euro-sign',
    items: [
      { text: 'Payment Enquiry', path: '/payments/enquiry' },
      { text: 'SEPA File Wizard', path: '/sepafilewizard' }
    ]
  },
  {
    expanded: true,
    text: 'Suppliers',
    icon: 'fas fa-user-friends',
    items: [
      { text: 'Supplier Accounts', path: '/suppliers/accounts' },
      { text: 'Supplier Subtypes', path: '/suppliers/subtypes' },
      { text: 'Supplier Types', path: '/suppliers/types' }
    ]
  },
  {
    expanded: true,
    text: 'Reports',
    icon: 'fas fa-database',
    items: [
      { text: 'Payment Detail', path: '/reports/PaymentDetail' },
      { text: 'Payment Enquiry', path: '/reports/PaymentEnquiry' },
      { text: 'Supplier Listing', path: '/reports/SupplierListing' }
    ]
  },
  {
    expanded: true,
    text: 'System',
    icon: 'fas fa-cogs',
    items: [
      { text: 'System Parameters', path: '/system/parameters' },
      { text: 'Bank Identifier Codes', path: '/system/bics' }
    ]
  }
];
