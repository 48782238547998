import React, { useContext } from 'react';
import ContextMenu from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';

import UserContext from '../../contexts/UserContext';

import './user-panel.scss';

const UserPanel = ({ menuMode, menuItems }) => {
  const { email } = useContext(UserContext);

  return (
    <div className="user-panel">
      <div className="user-info">
        <div className="image-container" />
        <div className="user-name">{email}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target=".user-button"
          showEvent="dxclick"
          width={170}
          position={{ my: 'top center', at: 'bottom center' }}
          cssClass="user-menu"
        />
      )}
      {menuMode === 'list' && (
        <List className="dx-toolbar-menu-action" items={menuItems} />
      )}
    </div>
  );
};

export default UserPanel;
