import React, { useEffect, useState } from 'react';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faPiggyBank, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { Route, Switch } from 'react-router-dom';

import initApolloClient from './apollo/apolloConfig';
import { AppSpinner, ProtectedRoute } from './components';
import UserContext from './contexts/UserContext';
import firebaseApp from './firebaseConfig';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import {
  AUTH_TOKEN,
  HASURA_CUSTOM_CLAIM_KEY,
  HASURA_TENANT_ID_CLAIM
} from './utils/constants';
import { sizes, subscribe, unsubscribe } from './utils/media-query';

import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import 'devextreme/dist/css/dx.common.css';

import './App.scss';
import './dx-styles.scss';

// Apollo
const apolloClient = initApolloClient();

const App = () => {
  const getScreenSizeClass = () => {
    const screenSizes = sizes();
    return Object.keys(screenSizes)
      .filter(cl => screenSizes[cl])
      .join(' ');
  };

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tenantId, setTenantId] = useState(0);
  const [email, setEmail] = useState('');
  const [screenSizeClass, setScreenSizeClass] = useState(getScreenSizeClass());

  // Subscribe to Firebase Auth State
  firebaseApp.auth().onAuthStateChanged(async user => {
    try {
      // If logged in save token to local state so we can attach it to our request header. For use with Hasura.
      if (user) {
        user.getIdTokenResult(true).then(result => {
          localStorage.setItem(AUTH_TOKEN, result.token);
          const { claims } = result;

          // Set User
          setTenantId(+claims[HASURA_CUSTOM_CLAIM_KEY][HASURA_TENANT_ID_CLAIM]);
          setEmail(claims.email);
        });
        setAuthenticated(true);
      } else {
        localStorage.removeItem(AUTH_TOKEN);
        setAuthenticated(false);
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    const screenSizeChanged = () => {
      setScreenSizeClass(getScreenSizeClass());
    };
    subscribe(screenSizeChanged);
    return () => unsubscribe(screenSizeChanged);
  }, []);

  return loading ? (
    <AppSpinner />
  ) : (
    <div className={`app ${screenSizeClass}`}>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <Switch>
            <Route exact path="/login" render={props => <Login {...props} />} />
            <UserContext.Provider value={{ email, tenantId }}>
              <ProtectedRoute
                authenticated={authenticated}
                component={Home}
                path="/"
              />
            </UserContext.Provider>
          </Switch>
        </ApolloHooksProvider>
      </ApolloProvider>
    </div>
  );
};

export default App;
