import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Template } from 'devextreme-react/core/template';
import { Button } from 'devextreme-react/button';
import DataGrid, {
  Column,
  HeaderFilter,
  IDataGridOptions,
  Pager,
  Paging,
  Selection,
  SearchPanel
} from 'devextreme-react/data-grid';
import { DocumentNode } from 'graphql';

import { AppSpinner, ErrorMessage } from '../..';

export interface ColumnProps {
  alignment?: any;
  caption: string;
  dataField: string;
  dataType?: any;
  format?: string | {};
  width?: string | number;
}

interface DataTableProps extends IDataGridOptions {
  addButtonHandler?: any;
  columns: ColumnProps[];
  defaultPageSize?: number;
  editButtonHandler?: any;
  keyField: string;
  rowAlternation?: boolean;
  selectMultiple?: boolean;
  showHeaderFilter?: boolean;
  showSearchPanel?: boolean;
  query: DocumentNode;
}

const DataTable: FunctionComponent<DataTableProps> = ({
  addButtonHandler,
  columns,
  defaultPageSize = 10,
  editButtonHandler,
  keyField,
  rowAlternation,
  selectMultiple = false,
  showHeaderFilter = false,
  showSearchPanel = false,
  query
}) => {
  const { data, error, loading } = useQuery(query);

  if (error && error.message) {
    return <ErrorMessage message={error.message} />;
  }

  if (loading) {
    return <AppSpinner />;
  }

  const handleToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'addButtonTemplate'
    });
  };

  // React nodes
  const addButtonRender = () => {
    return addButtonHandler ? (
      <Button
        icon="add"
        onClick={addButtonHandler}
        stylingMode="contained"
        type="default"
      />
    ) : null;
  };

  const dataSourceName = Object.keys(data)[0];

  return (
    <>
      <DataGrid
        // ref={grd}
        dataSource={data[dataSourceName]}
        // focusedRowEnabled={true}
        // defaultFocusedRowIndex={0}
        // columnHidingEnabled={true}
        columnAutoWidth
        keyExpr={keyField}
        onToolbarPreparing={
          addButtonHandler ? handleToolbarPreparing : undefined
        }
        rowAlternationEnabled={rowAlternation}
        showBorders
        showColumnLines={false}
      >
        {columns.map(col => (
          <Column
            alignment={col.alignment}
            caption={col.caption}
            dataField={col.dataField}
            dataType={col.dataType}
            key={col.dataField}
            width={col.width}
            format={col.format}
          />
        ))}

        {editButtonHandler ? (
          <Column
            buttons={[
              {
                icon: 'edit',
                hint: 'Edit',
                onClick: editButtonHandler
              }
            ]}
            alignment="left"
            caption="Actions"
            type="buttons"
            width="8%"
          />
        ) : null}

        <HeaderFilter visible={showHeaderFilter} />
        <Pager
          allowedPageSizes={[10, 25, 50, 100]}
          showPageSizeSelector
          showInfo
        />
        <Paging defaultPageSize={defaultPageSize} />
        {selectMultiple ? (
          <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="onClick"
          />
        ) : (
          undefined
        )}
        <SearchPanel
          placeholder="Search..."
          visible={showSearchPanel}
          width="20vw"
        />
        <Template name="addButtonTemplate" render={addButtonRender} />
      </DataGrid>
    </>
  );
};

export default DataTable;
