import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyAzCbn5n7aHNfmkU0SU_9rjArNdk-XyNO0',
  authDomain: 'newcourt-c76d7.firebaseapp.com',
  databaseURL: 'https://newcourt-c76d7.firebaseio.com',
  projectId: 'newcourt-c76d7',
  storageBucket: 'newcourt-c76d7.appspot.com',
  messagingSenderId: '349376411938',
  appId: '1:349376411938:web:d3179e1d66cbc0f9'
});

export default firebaseApp;
