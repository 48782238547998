import { gql } from 'apollo-boost';

export const AVAILABLE_SUPPLIERS = gql`
  query AvailableSuppliers($email: String!, $supplier_type_code: String!) {
    available_suppliers(
      where: {
        _and: {
          email: { _eq: $email }
          supplier_type_code: { _eq: $supplier_type_code }
        }
      }
    ) {
      email
      supplier_id
      first_name
      surname
      supplier_types {
        supplier_type_code
        name
      }
      iban
      note
    }
  }
`;

export const BANK_ACCOUNTS = gql`
  query BankAccounts {
    bank_accounts(order_by: { bank_account_code: asc }) {
      bank_account_code
      name
      bic
      iban
      oin
    }
  }
`;

export const BANK_ACCOUNT_FORM = gql`
  query BankAccountByPk($bank_account_code: String!, $tenant_id: Int!) {
    bank_accounts_by_pk(
      bank_account_code: $bank_account_code
      tenant_id: $tenant_id
    ) {
      bank_account_code
      name
      bic
      iban
      oin
    }
    bank_accounts {
      bank_account_code
    }
  }
`;

export const BANK = gql`
  query BankIdentifierCodes {
    bank_identifier_codes {
      bank_identifier_code
    }
  }
`;

export const BANK_IDENTIFIER_CODES = gql`
  query BankIdentifierCodes {
    bank_identifier_codes(order_by: { bank_identifier_code: asc }) {
      bank_identifier_code
      name
    }
  }
`;

export const BANK_IDENTIFIER_CODES_BY_PK = gql`
  query BankIdentifierCodeByPk(
    $bank_identifier_code: String!
    $tenant_id: Int!
  ) {
    bank_identifier_codes_by_pk(
      bank_identifier_code: $bank_identifier_code
      tenant_id: $tenant_id
    ) {
      bank_identifier_code
      name
    }
  }
`;

export const BANK_IDENTIFIER_CODES_FORM = gql`
  query BankIdentifierCodesForm(
    $bank_identifier_code: String!
    $tenant_id: Int!
  ) {
    bank_identifier_codes_by_pk(
      bank_identifier_code: $bank_identifier_code
      tenant_id: $tenant_id
    ) {
      bank_identifier_code
      name
    }
    bank_identifier_codes {
      bank_identifier_code
    }
  }
`;

export const PAYMENT_ENQUIRY = gql`
  query PaymentEnquiry {
    bank_accounts(order_by: { bank_account_code: asc }) {
      bank_account_code
      name
    }
    suppliers(order_by: { surname: asc }) {
      supplier_id
      first_name
      surname
      note
    }
    supplier_types(order_by: { name: asc }) {
      supplier_type_code
      name
    }
    users(order_by: { email: asc }) {
      email
    }
  }
`;

export const PAYMENT_FILE = gql`
  query PaymentsFile($payment_id: bigint!) {
    payment_enquiry_header(where: { payment_id: { _eq: $payment_id } }) {
      payment_id
      batch_number
      email
      bank_accounts {
        bank_account_code
        name
        bic
        iban
        oin
      }
      payment_reference
      time_processed
      total_amount
      detail_count
      payment_details {
        payment_detail_id
        supplier {
          supplier_id
          first_name
          surname
          note
          iban
          bic
          supplier_type {
            payment_ref
          }
        }
        amount
      }
    }
    system_parameters {
      company_name
      sepa_file_count
    }
  }
`;

export const PAYMENTS = gql`
  query Payments(
    $supplier_id: Int
    $supplier_type_code: String
    $email: String
    $bank_accoount_code: String
    $date_from: timestamptz
    $date_to: timestamptz
  ) {
    payment_enquiry_header(
      where: {
        _and: [
          {
            payment_details: {
              _and: [
                { supplier_id: { _eq: $supplier_id } }
                {
                  supplier: { supplier_type_code: { _eq: $supplier_type_code } }
                }
              ]
            }
          }
          { email: { _eq: $email } }
          { bank_account_code: { _eq: $bank_accoount_code } }
          { time_processed: { _gte: $date_from } }
          { time_processed: { _lt: $date_to } }
        ]
      }
      order_by: { payment_id: asc }
    ) {
      payment_id
      batch_number
      email
      bank_account_code
      payment_reference
      total_amount
      time_processed
      successful
      payment_details(
        where: {
          _and: [
            { supplier_id: { _eq: $supplier_id } }
            { supplier: { supplier_type_code: { _eq: $supplier_type_code } } }
          ]
        }
      ) {
        supplier_id
        payment_detail_id
        supplier {
          supplier_id
          first_name
          surname
          note
          supplier_type {
            supplier_type_code
            name
          }
        }
        amount
        successful
      }
    }
  }
`;

export const PAYMENT_FILE_DATA = gql`
  query PaymentFile_Data(
    $email: String!
    $bank_account_code: String!
    $tenant_id: Int!
  ) {
    bank_accounts_by_pk(
      bank_account_code: $bank_account_code
      tenant_id: $tenant_id
    ) {
      bic
      iban
      oin
    }
    payment_staging(where: { email: { _eq: $email } }) {
      payment_id
      amount
      supplier {
        supplier_id
        first_name
        surname
        iban
      }
      email
    }
    system_parameters {
      system_parameter_id
      company_name
      sepa_file_count
    }
  }
`;

export const PAYMENT_FILE_TOTALS = gql`
  query PaymentFile_Totals($email: String!) {
    payment_staging_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count(columns: supplier_id)
        sum {
          amount
        }
      }
    }
  }
`;

export const PAYMENT_STAGING = gql`
  query PaymentStaging($email: String!) {
    payment_staging(where: { email: { _eq: $email } }) {
      payment_id
      email
      supplier_id
      supplier {
        first_name
        surname
        note
        supplier_type {
          supplier_type_code
          name
        }
      }
      amount
      supplier_sub_type_code
    }
  }
`;

export const SEPA_FILE_WIZARD_OPTIONS = gql`
  query SepaFileWizardOptions {
    bank_accounts {
      bank_account_code
      name
    }
    supplier_types {
      supplier_type_code
      name
      payment_ref
    }
    supplier_sub_types {
      supplier_sub_type_code
      name
    }
  }
`;

export const SUPPLIER_FORM = gql`
  query SupplierForm($supplier_id: Int!) {
    suppliers_by_pk(supplier_id: $supplier_id) {
      supplier_id
      first_name
      surname
      supplier_type_code
      bic
      iban
      note
    }
    supplier_types {
      supplier_type_code
      name
    }
    bank_identifier_codes {
      bank_identifier_code
    }
  }
`;

export const SUPPLIER_TYPES = gql`
  query SupplierTypes {
    supplier_types(order_by: { name: asc }) {
      supplier_type_code
      name
      payment_ref
    }
  }
`;

export const SUPPLIERS = gql`
  query Suppliers {
    suppliers(order_by: { surname: asc, first_name: asc }) {
      supplier_id
      first_name
      surname
      supplier_type {
        supplier_type_code
        name
      }
      iban
      bic
      note
    }
  }
`;

export const SUPPLIER_BY_PK = gql`
  query SupplierByPk($supplier_id: Int!) {
    suppliers_by_pk(supplier_id: $supplier_id) {
      supplier_id
      first_name
      surname
      supplier_type_code
      bic
      iban
      note
    }
  }
`;

export const SUPPLIERS_BY_SUPPLIER_TYPE = gql`
  query SupplierBySupplierType($supplier_type_code: String!) {
    suppliers(where: { supplier_type_code: { _eq: $supplier_type_code } }) {
      supplier_id
      first_name
      surname
      supplier_type {
        supplier_type_code
        name
      }
      iban
      note
    }
  }
`;

export const SUPPLIER_SUB_TYPES = gql`
  query SupplierSubTypes {
    supplier_sub_types {
      supplier_sub_type_code
      name
    }
  }
`;

export const SUPPLIER_SUB_TYPES_BY_PK = gql`
  query SupplierSubTypesByPk(
    $supplier_sub_type_code: String!
    $tenant_id: Int!
  ) {
    supplier_sub_types_by_pk(
      supplier_sub_type_code: $supplier_sub_type_code
      tenant_id: $tenant_id
    ) {
      supplier_sub_type_code
      name
    }
  }
`;

export const SUPPLIER_SUB_TYPE_FORM = gql`
  query SupplierSubTypeForm(
    $supplier_sub_type_code: String!
    $tenant_id: Int!
  ) {
    supplier_sub_types_by_pk(
      supplier_sub_type_code: $supplier_sub_type_code
      tenant_id: $tenant_id
    ) {
      supplier_sub_type_code
      name
    }
    supplier_sub_types {
      supplier_sub_type_code
    }
  }
`;

export const SYSTEM_PARAMETERS = gql`
  query SystemParameters {
    system_parameters {
      system_parameter_id
      company_name
      sepa_file_count
    }
  }
`;

export const SUPPLIER_TYPE_FORM = gql`
  query SupplierTypeForm($supplier_type_code: String!, $tenant_id: Int!) {
    supplier_types_by_pk(
      supplier_type_code: $supplier_type_code
      tenant_id: $tenant_id
    ) {
      supplier_type_code
      name
      payment_ref
    }
    supplier_types {
      supplier_type_code
    }
  }
`;

export const USERS = gql`
  query Users {
    users {
      email
      name
    }
  }
`;
