import React from 'react';
import classNames from 'classnames';
import { Button } from 'devextreme-react/button';

import classes from './PageHeader.module.css';

interface PageHeaderProps {
  backHandler?: any;
  title: string;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  backHandler,
  title
}) => {
  return (
    <div className={classNames(classes.pageHeader, 'dx-card content-block')}>
      <div className={classes.container}>
        {backHandler ? (
          <Button
            hint="Back"
            icon="arrowleft"
            onClick={backHandler}
            type="default"
          />
        ) : null}
        <span className={classes.text}>{title}</span>
      </div>
    </div>
  );
};

export default PageHeader;
