import React from 'react';
// import Lookup, { ILookupOptions } from 'devextreme-react/lookup';
import { Lookup, ILookupOptions } from 'devextreme-react/lookup';
import { Field, FieldProps } from 'formik';

interface IDXLookupProps extends ILookupOptions {
  label?: string;
  required?: boolean;
  name: string;
}

const DXLookup: React.FC<IDXLookupProps> = ({
  label,
  name,
  required = false,
  ...rest
}) => {
  const lookupName: string = name || '';

  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps) => {
        const { errors, setFieldTouched, setFieldValue, touched } = form;

        // Valid until touched, then check for errors
        let valid = true;
        if (!!touched[lookupName] && !!errors[lookupName]) valid = false;

        const asterisk: React.ReactNode = required ? (
          <span className="required">*</span>
        ) : (
          undefined
        );

        const handleBlur = (): void => {
          setFieldTouched(lookupName);
        };

        const handleChange = ({ value }: any): void => {
          setFieldValue(lookupName, value);
        };

        return (
          <div className="form-container">
            <label>
              {label} {asterisk}
            </label>
            <Lookup
              {...field}
              {...rest}
              className="form-input"
              isValid={valid}
              onFocusOut={handleBlur}
              onValueChanged={handleChange}
              stylingMode="filled"
              validationError={{
                message: errors[lookupName] ? errors[lookupName] : ''
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default DXLookup;
