import React from 'react';

const AppContent = props => {
  return (
    <div className="content-block">
      <div {...props} className="dx-card responsive-paddings" />
    </div>
  );
};

export default AppContent;
