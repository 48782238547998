import React from 'react';

interface IErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FunctionComponent<IErrorMessageProps> = props => {
  return (
    <div
      {...props}
      style={{
        background: 'red',
        borderRadius: '0.25rem',
        color: 'white',
        fontWeight: 'bold',
        margin: '1rem 0',
        padding: '0.6rem'
      }}
    >
      An unexpected error has occured. Please contact the systems administrator:{' '}
      {props.message}
    </div>
  );
};

export default ErrorMessage;
