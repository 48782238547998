import React from 'react';
import { Button } from 'devextreme-react/button';
import { withApollo } from 'react-apollo';

import notify from '../../utils/app-notification';
import { PAYMENT_FILE, SYSTEM_PARAMETERS } from '../../apollo/queries';
import { COMPLETE_PAYMENTS } from '../../apollo/mutations';

import { fileTools } from '../../utils';

// interface IPaymentFileGeneratorProps {
//   client?: any;
//   disabled?: boolean;
//   email: string;
//   bankAccountCode: string;
//   onComplete: (batchNo: number) => void;
//   paymentDate: Date;
//   paymentRef: string;
//   stagingData: [];
// }

const PaymentFileGenerator = ({
  bankAccountCode,
  client,
  disabled,
  email,
  onComplete,
  paymentDate,
  paymentRef,
  stagingData
}) => {
  const handleClick = async () => {
    if (!email) {
      notify(
        'An error has occured. Please contact the system administrator.',
        'error'
      );
      return;
    }
    if (!bankAccountCode) {
      notify('Bank Account Code must be selected.', 'info');
      return;
    }

    try {
      // Get file count
      const { data: paramsData } = await client.query({
        query: SYSTEM_PARAMETERS
      });

      const { system_parameters: params } = paramsData;

      // Create payments object
      const payments = [
        {
          batch_number: params[0].sepa_file_count + 1,
          email,
          bank_account_code: bankAccountCode,
          payment_reference: paymentRef,
          time_processed: paymentDate,
          payments_details: {
            data: stagingData.map(p => ({
              supplier_id: p.supplier_id,
              amount: p.amount,
              supplier_sub_type_code: p.supplier_sub_type_code
            }))
          }
        }
      ];

      // Complete payments
      const response = await client.mutate({
        mutation: COMPLETE_PAYMENTS,
        variables: { payments, email }
      });

      // Retrieve data for file
      const { data } = await client.query({
        query: PAYMENT_FILE,
        variables: {
          payment_id:
            response.data.insert_payments_header.returning[0].payment_id
        }
      });

      const { payment_enquiry_header, system_parameters } = data;

      // Generate File
      fileTools.generatePaymentFile({
        payments: payment_enquiry_header[0],
        parameters: system_parameters[0]
      });

      // Update UI
      onComplete(system_parameters[0].sepa_file_count);
    } catch (err) {
      notify(err, 'error');
    }
  };

  return (
    <Button
      disabled={disabled}
      icon="save"
      onClick={handleClick}
      type="default"
    />
  );
};

export default withApollo(PaymentFileGenerator);
