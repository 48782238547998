import React, { useState } from 'react';
// import { Button, TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import Validator, { RequiredRule, EmailRule } from 'devextreme-react/validator';

import { SingleCard } from '../../layouts';
import firebaseApp from '../../firebaseConfig';

import classes from './Login.module.scss';

import Logo from '../../assets/images/CompanyLogo.png';

const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailValueChanged = ({ value }) => {
    setEmail(value);
  };

  const handlePasswordValueChanged = ({ value }) => {
    setPassword(value);
  };

  const handleLogin = async e => {
    e.preventDefault();

    try {
      await firebaseApp.auth().signInWithEmailAndPassword(email, password);
      history.push('/');
    } catch (err) {
      const { code } = err;
      if (code && code.indexOf('auth') > -1)
        notify(
          {
            message: 'Invalid Email or Password',
            position: { my: 'center top', at: 'center top' }
          },
          'error'
        );
      else {
        // message.error('An unexpected error has occurred.');
        console.error(err);
      }
    }
  };

  return (
    <SingleCard>
      <div className={classes.header}>
        <img src={Logo} alt="Company Logo" />
        <div className={classes.title}>
          <span>Payments</span>
        </div>
        <div>Sign In to your account</div>
      </div>
      <form onSubmit={handleLogin}>
        <div className="dx-field">
          <TextBox
            onValueChanged={handleEmailValueChanged}
            placeholder="Email"
            value={email}
            width="100%"
          >
            <Validator>
              <RequiredRule message="Email is required." />
              <EmailRule message="Email is invalid." />
            </Validator>
          </TextBox>
        </div>
        <div className="dx-field">
          <TextBox
            onValueChanged={handlePasswordValueChanged}
            mode="password"
            placeholder="Password"
            value={password}
            width="100%"
          >
            <Validator>
              <RequiredRule message="Password is required." />
            </Validator>
          </TextBox>
        </div>
        <div className="dx-field">
          <Button text="Login" type="default" useSubmitBehavior width="100%" />
        </div>
      </form>
    </SingleCard>
  );
};

export default Login;
