import React from 'react';
// import { LoadIndicator } from 'devextreme-react/load-indicator';
// import PropTypes from 'prop-types';

import classes from './AppSpinner.module.css';

const AppSpinner = () => {
  return <div className={classes.spinner}>{/* <LoadIndicator /> */}</div>;
};

AppSpinner.propTypes = {};

export default AppSpinner;
