import React from 'react';
import DataGrid, {
  IDataGridOptions,
  Scrolling,
  Selection
} from 'devextreme-react/data-grid';

interface IAppGridProps extends IDataGridOptions {
  selectionMode?: 'single' | 'multiple';
  virtualScrolling?: boolean;
}

const AppGrid: React.FunctionComponent<IAppGridProps> = ({
  children,
  selectionMode = 'single',
  virtualScrolling,
  ...rest
}) => {
  return (
    <DataGrid
      {...rest}
      rowAlternationEnabled
      showBorders
      showColumnLines={false}
    >
      {selectionMode === 'multiple' ? <Selection mode="multiple" /> : undefined}
      {virtualScrolling ? <Scrolling mode="virtual" /> : undefined}
      {children}
    </DataGrid>
  );
};

export default AppGrid;
