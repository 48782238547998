import { gql } from 'apollo-boost';

export const AUTH_TOKEN = 'firebase:token';

export const GRAPHQL_URL =
  // 'https://newcourt-hasura-demo.herokuapp.com/v1/graphql';
  'https://hasura.newcourt.software/v1/graphql';

export const HASURA_CUSTOM_CLAIM_KEY = 'https://hasura.io/jwt/claims';
export const HASURA_TENANT_ID_CLAIM = 'x-hasura-tenant-id';

export const QRY_BANK_ACCOUNTS = gql`
  query BankAccounts {
    bank_accounts(order_by: { bank_account_code: asc }) {
      bank_account_code
      name
      bic
      iban
      oin
    }
  }
`;

export const QRY_GET_SUPPLIERS = gql`
  query Suppliers {
    suppliers(order_by: { supplier_id: asc }) {
      supplier_id
      first_name
      surname
      supplier_type {
        name
      }
      iban
      note
    }
  }
`;

export const QRY_SUPPLIER_BY_PK = gql`
  query Suppliers($supplier_id: Int!) {
    suppliers_by_pk(supplier_id: $supplier_id) {
      first_name
      surname
      supplier_type_code
      iban
      note
    }
  }
`;

export const QRY_SUPPLIER_TYPES = gql`
  query SupplierTypes {
    supplier_types {
      supplier_type_code
      name
    }
  }
`;

export const QRY_SUPPLIER_TYPE_BY_PK = gql`
  query SupplierType($supplier_type_code: String!, $tenant_id: Int!) {
    supplier_types_by_pk(
      supplier_type_code: $supplier_type_code
      tenant_id: $tenant_id
    ) {
      supplier_type_code
      name
      payment_ref
    }
  }
`;

export const QRY_UPSERT_SUPPLIER = gql`
  mutation UpsertSupplier($objects: [suppliers_insert_input!]!) {
    insert_suppliers(
      objects: $objects
      on_conflict: {
        constraint: suppliers_iban_key
        update_columns: [
          first_name
          surname
          supplier_type_code
          iban
          bic
          note
        ]
      }
    ) {
      returning {
        supplier_id
        first_name
        surname
        supplier_type_code
        iban
        bic
        note
      }
    }
  }
`;

export const MUT_UPSERT_SUPPLIER_TYPE = gql`
  mutation UpsertSupplierType($objects: [supplier_types_insert_input!]!) {
    insert_supplier_types(
      objects: $objects
      on_conflict: {
        constraint: supplier_types_pkey
        update_columns: [name, payment_ref]
      }
    ) {
      returning {
        supplier_type_code
        name
        payment_ref
      }
    }
  }
`;
