import React from 'react';
import { Lookup, ILookupOptions } from 'devextreme-react/lookup';

interface IDXLookup extends ILookupOptions {}

const DXLookup: React.FC<IDXLookup> = ({ ...rest }) => {
  return <Lookup {...rest} />;
};

export default DXLookup;
