import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ authenticated, component: Component, rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return authenticated ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default ProtectedRoute;
